


























































import {Component, VModel, Vue, Watch} from 'vue-property-decorator';
import moment, {Moment} from 'moment';

@Component({
  components: {
    ToggleButtonGroupComponent: () => import(
      '@/components/shared/ToggleButtonGroup.component.vue'),
  },
})

export default class AdvDatePickerComponent extends Vue {
  @VModel({required: true})
  public currentCalendarType!: 'week' | 'day';

  private displayDate: Moment = moment();
  private startDate: Moment = moment();
  private isWeekView: boolean = false;
  private pickerValue = this.pickerFromMoment();
  private listenerAlreadySet: boolean = false;

  private get calendarTypes() {
    return [{
      text: this.$t('GENERAL.DAY').toString(),
      value: 'day',
    }, {
      text: this.$t('GENERAL.WEEK').toString(),
      value: 'week',
    }];
  }

  public mounted() {
    this.currentCalendarType = this.initCurrentCalendarType();
    this.startDate = this.getStartOfWeek();
    this.isWeekView = this.currentCalendarType === 'week';
  }

  @Watch('currentCalendarType')
  private onCurrentCalendarTypeChange() {
    this.isWeekView = this.currentCalendarType === 'week';
    this.startDate = this.currentCalendarType === 'day' ? this.displayDate : this.getStartOfWeek(this.displayDate);
    this.pickerValue = this.pickerFromMoment(this.startDate);
    this.$emit('calendar-type-change', this.currentCalendarType);
  }

  private setTimePeriod(direction: 'add' | 'subtract') {
    this.displayDate = this.displayDate.clone()[direction](this.currentCalendarType === 'week' ? 7 : 1, 'days');
    this.startDate = this.getStartOfWeek(this.displayDate);
    this.pickerValue = this.pickerFromMoment(this.startDate);
    this.updateActiveWeek();
    this.$emit('update', this.displayDate);
  }

  private goToToday() {
    this.displayDate = moment();
    this.pickerValue = this.pickerFromMoment();
    this.startDate = this.getStartOfWeek();
    this.$emit('update', this.displayDate);
  }

  private getStartOfWeek(date: Moment = moment()) {
    return date.startOf(this.currentCalendarType || 'week');
  }

  private initCurrentCalendarType(): 'week' | 'day' {
    const param = this.$route.query.calendarType as 'week' | 'day' || null;
    return param ?? 'week';
  }

  private onPickerValueChange(e: any) {
    this.displayDate = moment(e);
    this.startDate = this.getStartOfWeek(this.displayDate);
    this.pickerValue = this.pickerFromMoment(this.startDate);
    this.updateActiveWeek();
    this.$emit('update', this.displayDate);
  }

  private updatePicker() {
    this.$nextTick(() => {
      if (!this.listenerAlreadySet) {
        (this.$refs.datePicker as Vue).$el?.addEventListener('transitionend', () => this.updateActiveWeek());
        this.listenerAlreadySet = true;
      }
    });
    this.updateActiveWeek();
  }

  private setActiveWeek() {
    const ActiveDay = document.querySelector('.v-btn--active');
    ActiveDay?.parentElement?.parentElement?.classList.add('active-tr');

  }

  private remActiveWeek() {
    const ActiveWeek = document.querySelector('.active-tr');
    ActiveWeek?.classList.remove('active-tr');
  }

  private updateActiveWeek() {
    this.remActiveWeek();
    this.$nextTick(() => this.setActiveWeek());
  }

  private pickerFromMoment(date: Moment = moment()) {
    return date.format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
  }
}
